import React from 'react'
import Img from "gatsby-image"
import styled from '@emotion/styled'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import Section from './section'

const FeatureCardImage = styled.figure`
  text-align: center;
  height: 60px;
`

const Title = styled(Typography)`
  margin-bottom: ${props => props.theme.spacing(4)}px;
`

const Subtitle = styled(Typography)``

const List = styled.ul`
  margin: ${props => props.theme.spacing(3.5)}px 0;
`

const ListItem = styled.li`
  text-align: center;
  list-style-position: inside;
  margin: 0;
`

const Feature = ({ feature }) => (
  <Grid item xs={12} md={4}>
    <FeatureCardImage>
      <Img fixed={feature.image.childImageSharp.fixed}/>
    </FeatureCardImage>
    <Title variant='h3' align='center'>{feature.title}</Title>
    <Subtitle variant='subtitle2' align='center'>{feature.eyebrow}</Subtitle>
    {feature.items &&
      <List>
        {feature.items.map(item => (
          <ListItem key={item}>
            <Typography align="center" variant="body1" display="inline">{item}</Typography>
          </ListItem>
        ))}
      </List>
    }
  </Grid>
)

export default ({ features }) => {
  const Features = ({ features }) => (features.map((feature, index) => <Feature key={index} feature={feature} />))

  return (
    <Section>
      <Grid container spacing={6}>
        <Features features={features} />
      </Grid>
    </Section>
  )
}